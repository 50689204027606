import c from "classnames";

import { InputFieldProps } from "@/types/inputFields";

import Styles from "./label.module.scss";

type Props = {
	label?: string;
	htmlFor?: string;
	labelType?: InputFieldProps["labelType"];
	labelIsActive?: boolean;
	subLabel?: string;
	skinny?: boolean;
	className?: string;
} & Partial<HTMLLabelElement>;

export const Label = ({
	label,
	htmlFor,
	labelType,
	labelIsActive,
	subLabel,
	skinny,
	className = "",
	...rest
}: Props) => {
	if (!label) {
		return null;
	}

	return (
		<label
			htmlFor={htmlFor}
			className={c(Styles.label, {
				[Styles.labelInside]: labelType === "inner",
				[Styles.labelInsideActive]: labelIsActive,
				[Styles.noMargin]: skinny,
				[Styles.small]: labelType === "small",
				[Styles.narrow]: labelType === "narrow",
				[Styles.tiny]: labelType === "tiny",
				[className]: !!className,
				"visually-hidden": labelType === "hidden",
			})}
			{...rest}
		>
			{label}
			{subLabel && <span>{subLabel}</span>}
		</label>
	);
};
