import { useCallback, useMemo, useState } from "react";

import { useQuery } from "@apollo/client";

import { RECIPE_QUERY, getAllRecipesQuery } from "@/API/queries";
import {
	DatoProduct,
	DatoProductAsVariant,
	DatoProductVariant,
	LocalProduct,
	LocalProductVariant,
	ProductMeasurementUnits,
	SubAppProduct,
	SubAppProductVariant,
} from "@/types/Treats";
import { fetchFeaturedProducts } from "@/API/shop";

import { useUserDataStore } from "../store";

type ProductsMarkedAsVariants = {
	handle: string;
	sku: string;
	oldPrice: number;
}[];

const getAllVariantsMarkedAsProducts = (
	product: DatoProduct
): ProductsMarkedAsVariants => {
	return product?.variants
		.filter(
			({ useAnotherRecipeAsVariant, recipeToUseAsVariant }) =>
				!!useAnotherRecipeAsVariant && !!recipeToUseAsVariant?.handle
		)
		.map(({ sku, recipeToUseAsVariant: { handle }, oldPrice }) => ({
			handle: handle as string,
			sku,
			oldPrice: Number(oldPrice),
		}));
};

const createVariantsFromProductsMarkedAsVariants = (
	productsMarkedAsVariants: ProductsMarkedAsVariants,
	products: DatoProduct[]
): DatoProductAsVariant[] => {
	const mappedVariants = productsMarkedAsVariants.map(
		({ handle, oldPrice }): null | DatoProductAsVariant => {
			const fullRecipeInfo = products.find(
				({ handle: recipeHandle }) => recipeHandle === handle
			);
			if (!fullRecipeInfo) {
				return null;
			}

			return {
				availableToBuy: true,
				sku: fullRecipeInfo?.variants[0].sku,
				title: fullRecipeInfo?.title,
				weight: fullRecipeInfo?.variants[0].weight,
				productImage: fullRecipeInfo?.images[0].responsiveImage ?? null,
				useAnotherRecipeAsVariant: false,
				oldPrice: `${oldPrice}`,
				description: fullRecipeInfo.description,
				recipeToUseAsVariant: {
					handle,
				},
			};
		}
	);

	return mappedVariants.filter(
		(val): val is DatoProductAsVariant => val !== null
	);
};

const mapFullInfoOfProductsAsVariantsToSubAppDataProductInfo = (
	pseudoVariants: DatoProduct["variants"],
	subAppProducts: SubAppProduct[]
) => {
	return pseudoVariants
		?.map(({ sku, recipeToUseAsVariant: { handle } }) => {
			const fullInfo = subAppProducts.find(
				({ handle: subAppHandle }) => subAppHandle === handle
			);

			return fullInfo?.product.variants.find(
				({ sku: subAppSku }) => sku === subAppSku
			);
		})
		.filter((val) => !!val);
};

const convertMeasurementUnitToReadableString = (
	unit: ProductMeasurementUnits,
	measurement: string
) => {
	switch (unit) {
		case "bags":
			return `${measurement} bags`;
		case "grams":
			return parseFloat(measurement) > 1000
				? `${parseInt(measurement) / 1000}kg`
				: `${measurement}g`;
		case "kg":
			return `${measurement}kg`;
		case "ml":
			return parseFloat(measurement) > 1000
				? `${measurement}l`
				: `${measurement}ml`;
		case "none":
			return measurement;
		default:
			return parseFloat(measurement) > 1000
				? `${parseInt(measurement) / 1000}kg`
				: `${measurement}g`;
	}
};

const mapDatoAndSubAppVariantToLocalVariant = (
	datoVariants: (DatoProductVariant | DatoProductAsVariant)[],
	subAppVariants: SubAppProduct["product"]["variants"],
	fullDatoProductInformation: DatoProduct,
	fullSubAppProductInformation: SubAppProduct
): LocalProductVariant[] => {
	const mappedVariants = subAppVariants.map(
		({
			sku: subAppSku,
			id,
			price,
			price_tests,
			title: subAppTitle,
		}): LocalProductVariant | null => {
			const datoVariantInformation = datoVariants.find(
				({ sku }) => sku === subAppSku
			);

			// If subapp info can't be found, remove the variant as the variant won't be able to be used without an id.
			if (
				!datoVariantInformation &&
				fullSubAppProductInformation.type !== "product"
			) {
				return null;
			}

			const {
				sku,
				title: dataTitle,
				productImage,
				oldPrice,
				availableToBuy,
				weight,
				...properties
			} = datoVariantInformation || {
				sku: subAppSku,
				title: subAppTitle,
				productImage: null,
				oldPrice: null,
				availableToBuy: false,
				weight: subAppTitle,
			};

			const description = properties.hasOwnProperty("description")
				? (properties as DatoProductAsVariant).description
				: fullDatoProductInformation.description;

			// If it's a chew, use the product title, not the variant title. Chews titles are small/medium/large
			const title =
				fullSubAppProductInformation.type === "chew"
					? fullDatoProductInformation.title
					: dataTitle || fullDatoProductInformation.title;

			// Size is used as the display, weight is only used for data. Size is 80g, 90 bags, Large etc.
			// if it's a chew, use the title as the size. Chews titles are small/medium/large.
			const size =
				fullSubAppProductInformation.type === "chew"
					? dataTitle
					: fullSubAppProductInformation.type === "bundle"
						? `${fullSubAppProductInformation.product.bundle_item_variants.length} items`
						: convertMeasurementUnitToReadableString(
								fullDatoProductInformation.unitMeasurement,
								weight ?? title
							);

			return {
				sku,
				size,
				weight: parseFloat(weight) ?? parseFloat(title),
				// TODO this image part is probably needing a bit of work to get the types right.
				image: productImage?.responsiveImage
					? [
							{
								responsiveImage: productImage.responsiveImage,
								alt: productImage.responsiveImage.alt || "",
								height: productImage.responsiveImage.height || 100,
								url: productImage.responsiveImage.src || "",
								width: productImage.responsiveImage.width,
							},
						]
					: fullDatoProductInformation.images,
				oldPrice: oldPrice ? parseFloat(oldPrice) : null,
				availableToBuy,
				title,
				description,
				variant_id: id,
				price: price / 100,
				price_tests,
			};
		}
	);

	return mappedVariants.filter(
		(val): val is LocalProductVariant => val !== null
	);
};

// This is where any missing information should be added. If it's missing from API data, add it to the api data then add it to the map here.
// This should be the only point of contact between the front end and the treats information from the API and Dato.
// Do not query separately.

const mapDatoAndSubAppProductToLocalProduct = (
	datoProduct: DatoProduct,
	subAppProduct: SubAppProduct,
	datoVariants: (DatoProductVariant | DatoProductAsVariant)[],
	subAppVariants: SubAppProductVariant[],
	allDatoRecipes: DatoProduct[],
	allSubAppRecipes: SubAppProduct[]
): LocalProduct => {
	const variants = mapDatoAndSubAppVariantToLocalVariant(
		datoVariants,
		subAppVariants,
		datoProduct,
		subAppProduct
	);
	const variantsInOrderOfPrice = variants.sort(
		({ price: a }, { price: b }) => a - b
	);
	const priceStartsFrom = variantsInOrderOfPrice?.[0]?.price;
	const oldPriceStartsFrom = variantsInOrderOfPrice?.[0]?.oldPrice || undefined;

	const totalVariantsAvailable = variants.filter(
		({ availableToBuy }) => availableToBuy
	)?.length;

	const isPreOrder = true && subAppProduct.product.seasonal_start_date;

	const isBundle = subAppProduct.type === "bundle";

	const bundlesInfo: LocalProduct["productsInBundle"] = isBundle
		? subAppProduct.product.bundle_item_variants.map(
				({ id: bundleId, ...rest }) => {
					const subAppRecipe = allSubAppRecipes.find(
						({ product: { variants } }) =>
							variants.find(({ id }) => id === bundleId)
					);

					const datoRecipe = allDatoRecipes.find(
						({ handle }) => subAppRecipe?.handle === handle
					);

					return {
						handle: subAppRecipe?.handle,
						description: datoRecipe?.description,
						id: bundleId,
						...rest,
						title: datoRecipe?.title || rest.title,
						images: datoRecipe?.images,
					};
				}
			)
		: null;

	return {
		allergens: subAppProduct.allergens,
		health_warnings: subAppProduct.health_warnings,
		handle: datoProduct.handle,
		title: datoProduct.title,
		subTitle: datoProduct.subtitle,
		description: datoProduct.description ?? datoProduct.productDescription,
		slug: datoProduct.slug,
		order: subAppProduct.order,
		benefits: subAppProduct.benefits,
		tags: subAppProduct.tags,
		type: subAppProduct.type,
		suitable: subAppProduct.suitable,
		information: datoProduct.information,
		hideBundleItems: !!datoProduct.hideBundleItems,
		unitMeasurement: datoProduct.unitMeasurement
			? datoProduct.unitMeasurement
			: "grams",

		showInFunnel: subAppProduct.show_in_funnel,
		outOfStock: datoProduct.outOfStock,
		discontinued: subAppProduct.discontinued,
		oneOff: subAppProduct.one_off,
		isPreOrder,
		isSeasonal: subAppProduct.product.is_seasonal,
		seasonalDates: {
			from: subAppProduct.product.seasonal_start_date
				? new Date(subAppProduct.product.seasonal_start_date)
				: null,
			to: subAppProduct.product.seasonal_end_date
				? new Date(subAppProduct.product.seasonal_end_date)
				: null,
		},

		isBundle,
		isUsedAsVariantOfAnotherProduct: !!datoProduct.isAVariant,

		productsInBundle: bundlesInfo,

		images: datoProduct.images.map(({ responsiveImage }) => responsiveImage),
		advertImage: datoProduct.advertImage,

		priceStartsFrom,
		oldPriceStartsFrom,
		totalVariantsAvailable,

		variants: variantsInOrderOfPrice,

		showInShop: datoProduct.showInShop,
		recipeType: datoProduct.recipeType,

		nutritionalInformation: {
			protein: datoProduct.protein,
			proteinAmount: datoProduct.protein,
			kcalAmount: datoProduct.kcalAmount,
			ingredientInformation: datoProduct.ingredientInformation,
			nutritionalAdditives: datoProduct.nutritionalAdditives,
			phosphorusAmount: datoProduct.phosphorusAmount,
			ashAmount: datoProduct.ashAmount,
			calciumAmount: datoProduct.calciumAmount,
			fatAmount: datoProduct.fatAmount,
			fibreAmount: datoProduct.fibreAmount,
			feedingGuide: datoProduct.feedingGuide,
		},
	};
};

export const useRecipeAPIData = () => {
	const [isLoading, setIsLoading] = useState(false);

	const checkout = useUserDataStore((state) => state.checkout);

	const { data: datoData, loading: datoLoading } = useQuery<{
		allRecipes: DatoProduct[];
	}>(getAllRecipesQuery, {
		context: { clientName: "dato" },
		fetchPolicy: "network-only",
		nextFetchPolicy: "standby",
	});

	const { data: subAppData, loading: subAppLoading } = useQuery<{
		recipes: SubAppProduct[];
	}>(RECIPE_QUERY, {
		variables: {
			type: "product,treat,supplement,christmas,vegi,cat,chew,bundle,accessory",
		},
		context: {
			clientName: "subApp",
			fetchPolicy: "network-only",
			nextFetchPolicy: "standby",
		},
	});

	const datoRecipes = datoData?.allRecipes;

	const subAppRecipes = subAppData?.recipes;

	const convertAPIDataToLocalObject = useCallback(
		(datoRecipes: DatoProduct[], subAppRecipes: SubAppProduct[]) => {
			return datoRecipes
				.map((datoRecipe) => {
					const subAppProductData = subAppRecipes?.find(
						({ handle }) => datoRecipe.handle === handle
					);

					// if the recipe is in dato but not in sub app, we exclude it
					if (!subAppProductData) {
						return null;
					}

					// Find all the recipes with the "is a variant of another product" flag set
					const productsMarkedAsVariants =
						getAllVariantsMarkedAsProducts(datoRecipe);

					// Create the variants of (product 1), from another (product 2) that was marked as a variant of (product 1)
					const createdVariantsFromProductsMarkedAsVariants =
						createVariantsFromProductsMarkedAsVariants(
							productsMarkedAsVariants,
							datoRecipes
						);

					// Create the sub app data of those variants just created
					const subAppDataOfProductsAsVariants =
						mapFullInfoOfProductsAsVariantsToSubAppDataProductInfo(
							createdVariantsFromProductsMarkedAsVariants,
							subAppRecipes
						);

					// Merge the variants of the product AND the created variants from another product into one.
					const allSubAppVariantsCompiled = [
						...(subAppProductData?.product.variants ?? []),
						...(subAppDataOfProductsAsVariants ?? []),
					].filter(
						(val): val is SubAppProduct["product"]["variants"][number] => !!val
					);

					// Get all the variants that aren't using another product as their variant
					const originalDatoVariants = datoRecipe?.variants?.filter(
						({ useAnotherRecipeAsVariant }) => !useAnotherRecipeAsVariant
					);

					// Merge the variants created from other products, and the variants of the product together.
					const allDatoVariantsCompiled = [
						...(originalDatoVariants ?? []),
						...(createdVariantsFromProductsMarkedAsVariants ?? []),
					].filter(
						(val): val is DatoProductVariant | DatoProductAsVariant => !!val
					);

					// This is the final bit, create our local copy of the recipe. This is the only data that the application should be using outside of this file.
					const localProductData = mapDatoAndSubAppProductToLocalProduct(
						datoRecipe,
						subAppProductData,
						allDatoVariantsCompiled,
						allSubAppVariantsCompiled,
						datoRecipes,
						subAppRecipes
					);

					return localProductData;
				})
				.filter((val): val is LocalProduct => val !== null);
		},
		[]
	);

	const dataMappedToLocalObject: LocalProduct[] = useMemo(() => {
		if (!datoRecipes || !subAppRecipes) {
			return [];
		}

		const convertedAPIDataToLocalData = convertAPIDataToLocalObject(
			datoRecipes,
			subAppRecipes
		);

		return convertedAPIDataToLocalData;
	}, [convertAPIDataToLocalObject, datoRecipes, subAppRecipes]);

	const sortedProducts = useMemo(
		() =>
			dataMappedToLocalObject.sort(
				({ order: order1 }, { order: order2 }) => order1 - order2
			),
		[dataMappedToLocalObject]
	);

	const recipes = useMemo(
		() =>
			sortedProducts.filter(
				({ type, isUsedAsVariantOfAnotherProduct }) =>
					type === "product" && !isUsedAsVariantOfAnotherProduct
			),
		[sortedProducts]
	);

	const extras = useMemo(
		() => sortedProducts.filter(({ type }) => type !== "product"),
		[sortedProducts]
	);

	const treatsAndChews = useMemo(
		() => [
			...extras.filter(
				({ type, isUsedAsVariantOfAnotherProduct }) =>
					(type === "treat" || type === "chew") &&
					!isUsedAsVariantOfAnotherProduct
			),
		],
		[extras]
	);

	const supplements = useMemo(
		() =>
			extras.filter(
				({ type, isUsedAsVariantOfAnotherProduct }) =>
					type === "supplement" && !isUsedAsVariantOfAnotherProduct
			),
		[extras]
	);

	const bundles = useMemo(
		() =>
			extras.filter(
				({ type, isUsedAsVariantOfAnotherProduct }) =>
					type === "bundle" && !isUsedAsVariantOfAnotherProduct
			),
		[extras]
	);

	const accessories = useMemo(
		() =>
			extras.filter(
				({ type, isUsedAsVariantOfAnotherProduct }) =>
					type === "accessory" && !isUsedAsVariantOfAnotherProduct
			),
		[extras]
	);

	const christmas = useMemo(
		() =>
			extras.filter(
				({ type, isUsedAsVariantOfAnotherProduct }) =>
					type === "christmas" && !isUsedAsVariantOfAnotherProduct
			),
		[extras]
	);

	const funnel = useMemo(
		() => extras.filter(({ showInFunnel }) => showInFunnel),
		[extras]
	);

	const getFeaturedProducts = useCallback(async () => {
		setIsLoading(true);
		const {
			recipes: featuredProducts,
			sideIcons,
			backgroundColour,
			title,
		} = await fetchFeaturedProducts();
		setIsLoading(false);

		// map the checkout items to just a list of SKUs
		const checkoutSKUs = checkout?.lineItems?.flatMap(({ items }) =>
			items.map(({ sku }) => sku)
		);

		return {
			backgroundColour,
			sideIcons,
			title,
			products: featuredProducts
				.map(({ handle: featuredHandle }) =>
					sortedProducts.find(({ handle }) => handle === featuredHandle)
				)
				.filter(
					(val): val is LocalProduct =>
						!!val &&
						// Check if the SKU of a featured product's variants is currently in the checkout anywhere, and exclude if it is
						!val.variants.find(
							({ sku }) =>
								!!checkoutSKUs?.find((checkoutSKU) => checkoutSKU === sku)
						)
				),
		};
	}, [checkout?.lineItems, sortedProducts]);

	return {
		datoRecipeData: datoData,
		subAppRecipeData: subAppData,
		sortedProducts,
		extras: {
			all: extras,
			categories: {
				treatsAndChews,
				supplements,
				bundles,
				accessories,
				christmas,
				funnel,
			},
		},
		convertAPIDataToLocalObject,
		getFeaturedProducts,
		recipes,
		allProducts: [...recipes, ...extras],
		isLoading: (datoLoading && !datoData) || subAppLoading || isLoading,
	};
};
