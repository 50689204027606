import c from "classnames";

import { Icon } from "@/components/FormElements/Icon";

import { useQuantitySelector } from "./useQuantitySelector";
import Styles from "./quantitySelector.module.scss";

type Props = {
	quantity: number;
	setQuantity: (quantity: number) => void;
	isABundle?: boolean;
	colour?: "yellow" | "green";
	isLoadingQuantity?: boolean;
	tabIndex?: number;
	disabled?: boolean;
	className?: string;
};

export const QuantitySelector = ({
	quantity,
	setQuantity,
	colour = "yellow",
	isLoadingQuantity,
	tabIndex = 0,
	disabled,
	className,
}: Props) => {
	const { isLoading, lessBtnClick, moreBtnClick } = useQuantitySelector({
		quantity,
		setQuantity,
	});

	return (
		<div
			className={c(Styles.quantitySelector, {
				[Styles[colour]]: quantity < 1,
				[`${className}`]: !!className,
			})}
		>
			<button
				type="button"
				className={Styles.lessButton}
				onClick={lessBtnClick}
				disabled={isLoadingQuantity || disabled}
				tabIndex={tabIndex}
			>
				<Icon icon={"IconMinus"} className={Styles.icon} />
			</button>{" "}
			{isLoadingQuantity ? (
				<Icon icon={"LoadingSpinner"} className={Styles.loading} />
			) : (
				<input
					className={c(Styles.inputSmall, { "is-loading": isLoading })}
					type="text"
					name="quantity"
					value={quantity || 0}
					aria-label="Quantity"
					readOnly
					tabIndex={tabIndex}
				/>
			)}{" "}
			<button
				type="button"
				className={Styles.moreButton}
				onClick={moreBtnClick}
				disabled={isLoadingQuantity || disabled}
				tabIndex={tabIndex}
			>
				<Icon icon={"IconPlus"} className={Styles.icon} />
			</button>
		</div>
	);
};
