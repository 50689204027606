import { useCallback, useMemo, useState } from "react";

import ReactDOMServer from "react-dom/server";

import { useAccountPage } from "@/hooks/useAccountPage";
import { track } from "@/helpers/analytics";
import { toSentence } from "@/helpers/helperFunctions";
import { useMediaQueries } from "@/hooks/useMediaQueries";
import { useRafPromotion } from "@/hooks/useRafPromotion";

export const useAccountRaf = () => {
	useAccountPage();

	const [isCopied, setIsCopied] = useState(false);

	const { isDesktop } = useMediaQueries();

	const { currentScheme, schemes, isInitialPromotion } = useRafPromotion();

	const copyLink = useCallback((text: string) => {
		track("RAF share", {
			category: "RAF",
			method: "copy",
			currentRafPromotion: currentScheme?.discountCode,
		});
		navigator.clipboard.writeText("http://" + text);
		setIsCopied(true);
		setTimeout(() => {
			setIsCopied(false);
		}, 2000);
	}, []);

	const doShare = useCallback((method: string) => {
		track("RAF share", {
			category: "RAF",
			method: method,
			currentRafPromotion: currentScheme?.discountCode,
		});
	}, []);

	const shareText = useMemo(
		() =>
			currentScheme ? (
				<>
					{`Get ${
						currentScheme?.discountCode?.type === "fixed"
							? "£" + (currentScheme.discountCode?.amount / 100).toFixed(0)
							: currentScheme.discountCode?.amount + "%"
					} off your first box ${currentScheme?.discountCode?.future_discounts
						?.map(
							(discount) => `
								and ${
									discount?.type === "fixed"
										? "£" + (discount.amount / 100).toFixed(0)
										: discount?.amount + "%"
								} off your next`
						)
						.join(
							" "
						)} delivery of delicious, wholesome, healthy dog food. Click the link to
						get a special discount, so your dog can start enjoying delicious food
						made for a long healthy life!`}
				</>
			) : (
				<></>
			),
		[currentScheme]
	);

	const shareSubject = useMemo(
		() =>
			currentScheme ? (
				<>
					Your healthy dog food discount from{" "}
					{currentScheme.referralScheme?.name}
					{(currentScheme.referralScheme?.pets.length || 0) > 0 && (
						<>
							{" "}
							and{" "}
							{currentScheme.referralScheme &&
								toSentence(currentScheme.referralScheme?.pets)}
						</>
					)}
				</>
			) : (
				<></>
			),
		[currentScheme]
	);

	const whatsappLink =
		"https://api.whatsapp.com/send?text=" +
		encodeURIComponent(ReactDOMServer.renderToStaticMarkup(shareText)) +
		" https://www.purepetfood.com/s/" +
		currentScheme?.referralScheme?.token +
		"/w";

	const facebookLink =
		"https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fpurepetfood.com/s/" +
		currentScheme?.referralScheme?.token +
		"/f&quote=" +
		encodeURIComponent(ReactDOMServer.renderToStaticMarkup(shareText));

	const emailLink =
		"mailto:?subject=" +
		encodeURIComponent(ReactDOMServer.renderToStaticMarkup(shareSubject)) +
		"&body=" +
		encodeURIComponent(ReactDOMServer.renderToStaticMarkup(shareText)) +
		" https://www.purepetfood.com/s/" +
		currentScheme?.referralScheme?.token +
		"/e";

	const shareLink = () => {
		if (navigator.share) {
			track("RAF share", {
				category: "RAF",
				method: "share",
				currentRafPromotion: currentScheme?.discountCode,
			});
			navigator
				.share({
					title: ReactDOMServer.renderToStaticMarkup(shareSubject),
					text: ReactDOMServer.renderToStaticMarkup(shareText),
					url:
						"https://www.purepetfood.com/s/" +
						currentScheme?.referralScheme?.token +
						"/s",
				})
				.then(() => console.log("Successful share"))
				.catch((error) => console.log("Error sharing", error));
		}
	};

	return {
		copyLink,
		shareLink,
		shareText,
		whatsappLink,
		facebookLink,
		emailLink,
		doShare,
		currentScheme,
		isCopied,
		schemes,
		isDesktop,
		isInitialPromotion,
	};
};
