import { useCallback, useEffect, useRef } from "react";

export const useOuterClick = (callback: () => void) => {
	const innerRef = useRef();
	const callbackRef = useRef<(e: MouseEvent) => void>();

	// set current callback in ref, before second useEffect uses it
	useEffect(() => {
		// useEffect wrapper to be safe for concurrent mode
		callbackRef.current = callback;
	});

	const handleClick = useCallback((e: MouseEvent) => {
		if (
			innerRef.current &&
			callbackRef.current &&
			// @ts-ignore TODO
			!innerRef?.current?.contains(e.target)
		) {
			callbackRef.current(e);
		}
	}, []);

	useEffect(() => {
		// read most recent callback and innerRef dom node from refs

		document.addEventListener("click", handleClick);
		return () => document.removeEventListener("click", handleClick);
	}, [handleClick]); // no need for callback + innerRef dep

	return innerRef; // return ref; client can omit `useRef`
};
