import c from "classnames";

import Styles from "./slideToggle.module.scss";

import { Icon } from "../Icon";

type Props = {
	name: string;
	label?: string;
	onChange: (value: boolean) => void;
	value?: boolean;
	disabled?: boolean;
	isLoading?: boolean;
	dark?: boolean;
};

export const SlideToggle = ({
	name,
	label = "",
	onChange,
	value,
	disabled = false,
	isLoading = false,
	dark = false,
}: Props) => {
	const isDisabled = disabled || isLoading;
	return (
		<label
			className={c(Styles.wrapper, {
				[Styles.disabled]: isDisabled,
				[Styles.dark]: dark,
			})}
			htmlFor={name}
		>
			<input
				type="checkbox"
				onChange={(e) => onChange(e.target.checked)}
				id={name}
				name={name}
				checked={value}
				disabled={isDisabled}
			/>
			<div className={Styles.inputRadio}>
				{isLoading ? (
					<div className={Styles.loading} />
				) : (
					<>
						<Icon className={Styles.cross} icon="Close" />
						<Icon className={Styles.tick} icon="IconTick" />
					</>
				)}
			</div>
			{label}
		</label>
	);
};
