import React, { LegacyRef } from "react";

import {
	FieldErrorsImpl,
	UseFormRegister,
	UseFormReturn,
	ValidationRule,
} from "react-hook-form";

import { IconOptions } from "@/components/FormElements/Icon";

import { Image } from "./page";

export enum SelectFieldTypes {
	normal = "normal",
	search = "search",
	multi = "multi",
	link = "link",
}

export type CheckboxOption<T> = {
	id: T;
	label: string;
	order?: number;
	infoTitle?: string;
	infoDesc?: string;
	info?: string;
};

export type Validation = {
	required?: boolean;
	maxLength?: number;
	minLength?: number;
	validate?: (value: any) => boolean | string | undefined;
	max?: number;
	min?: number;
	pattern?: ValidationRule<RegExp>;
	deps?: string[];
};

export type ErrorMessages = {
	required?: string;
	pattern?: string;
	max?: string;
	min?: string;
	validate?: React.ReactNode;
	minLength?: string;
	maxLength?: string;
};

export type FieldErrors = Record<string, Record<string, unknown>>;

export type BaseFieldProps = {
	label?: string;
	id?: string;
	name: string;
	subLabel?: string;
	register?: UseFormRegister<any>;
	validate?: Validation;
	showErrors?: boolean;
	labelClassName?: string;
	labelType?: "normal" | "inner" | "small" | "narrow" | "tiny" | "hidden";
	errors?: Partial<
		FieldErrorsImpl<{
			[x: string]: {
				[y: string]: unknown;
			}[];
		}>
	>;
	extraText?: string;
	errorMessages?: ErrorMessages;
	errorClassName?: string;
	value?: string;
	onChange?: (value: string) => void;
	inputWidth?: "smaller" | "bigger";
	errorMessageAddressee?: string;
	addressee?: string;
	children?: React.ReactNode;
	invalid?: boolean;
	unitToAppendDisplayValue?: string;
};

export enum ButtonVariants {
	default = "default",
	clear = "clear",
	ghost = "ghost",
	negative = "negative",
	clearAlternate = "clearAlternate",
	clearLight = "clearLight",
	clearNegative = "clearNegative",
	facebook = "facebook",
	whatsapp = "whatsapp",
	ghostLight = "ghostLight",
}

export type ButtonIconProps = {
	icon: IconOptions;
	width?: string;
	height?: string;
};

export type ButtonProps = React.HTMLProps<HTMLButtonElement> & {
	url?: string;
	section?: Record<string, unknown>;
	colour?: string;
	disabled?: boolean;
	variant?: ButtonVariants;
	type?: "button" | "reset" | "submit";
	leftIcon?: ButtonIconProps;
	rightIcon?: ButtonIconProps;
	className?: string;
	selected?: boolean;
	isWorking?: boolean;
	sizing?: "small" | "smaller" | "normal" | "large" | "tiny";
	width?: "full" | "relative";
	underline?: boolean;
	active?: boolean;
	maintainPadding?: boolean;
	center?: boolean;
	fancyWorking?: boolean;
	forwardRef?: LegacyRef<HTMLButtonElement>;
	iconSpaced?: boolean;
	image?: Image;
	left?: boolean;
	darkWorkingIcon?: boolean;
};

export type InputFieldProps = BaseFieldProps &
	Omit<React.HTMLProps<HTMLInputElement>, "onChange"> & {
		track?: boolean;
		onQuantityIncrease?: (newValue: string) => void;
		onQuantityDecrease?: (newValue: string) => void;
		increment?: number;
		inputClassName?: string;
		afterIcon?: React.ReactNode;
		beforeIcon?: React.ReactNode;
		leftAlign?: boolean;
		hasControls?: boolean;
		hideIcon?: boolean;
		floatingErrors?: boolean;
	};

export type SelectOption = {
	label: string;
	recommended?: string;
	value: string | number;
	caption?: string;
};

export type SelectProps = Omit<BaseFieldProps, "onChange"> &
	Omit<React.HTMLProps<HTMLInputElement>, "onChange"> & {
		options?: SelectOption[];
		value?: any;
		setFocus?: UseFormReturn["setFocus"];
		onChange?: (option: string, label?: string) => void;
		leftAlign?: boolean;
		inline?: boolean;
		floatingErrors?: boolean;
		menuAbove?: boolean;
		onInput?: (val: string) => void;
		sizing?: "normal" | "tiny";
	};

export type RadioOption<T> = {
	id: T;
	label: string | React.ReactNode;
	icon?: {
		icon: IconOptions;
		width: string;
		height: string;
		heightToOccupy?: string;
	};
	caption?: string;
	helpText?: string;
	helpImage?: string;
	disabled?: boolean;
};

export type StandardRadioOption = {
	value: string;
	label: string | React.ReactElement;
	children?: StandardRadioOption[];
	disabled?: boolean;
	isDefault?: boolean;
};

export type RadioProps = Omit<BaseFieldProps, "onChange"> & {
	toggle?: boolean;
	options: RadioOption<unknown>[];
	className?: string;
	narrow?: boolean;
	small?: boolean;
	onChange?: (ev: string | number | null) => void;
	wrapperClassname?: string;
	skinny?: boolean;
	required?: boolean;
	noCircle?: boolean;
};

export type CheckboxProps = Omit<BaseFieldProps, "value"> & {
	options?: CheckboxOption<string>[];
	maxValues?: number;
	type?: "normal" | "info";
	isLargeSize?: boolean;
	value?: string | string[];
	className?: string;
	wideLayout?: boolean;
};
