import { useEffect, useRef } from "react";

export const usePreventScroll = ({
	modalOpen,
	id,
}: {
	modalOpen: boolean;
	id: string;
}) => {
	const pageLockPosition = useRef(0);

	// Set page to not scroll with the id stored

	// If there's already a class added to stop scroll, don't add another (overlay page handling)

	// Remove class when modalOpen is changed to false

	useEffect(() => {
		//next.js
		if (typeof window === "undefined") {
			return;
		}

		const thisInstancesElement = document.getElementsByClassName(
			`lockScroll_${id}`
		)?.[0];

		const element = document.querySelectorAll("[class*='lockScroll']")?.[0];

		// If this instance has set the scroll lock, exit
		if (modalOpen && thisInstancesElement) {
			return;
		}

		// If the modal is not open and there's no element set, exit
		if (!modalOpen && !element) {
			return;
		}

		// If the modal is open and the page is locked, exit
		if (modalOpen && element) {
			return;
		}

		// If modal is no longer open and this instance has locked the page, remove
		if (!modalOpen && thisInstancesElement) {
			element.classList.remove(`lockScroll_${id}`);
			document.documentElement.scrollTo({
				top: pageLockPosition.current,
				behavior: "instant",
			});
		}

		// If modal is now open, but the page isn't locked, lock it
		if (modalOpen && !element) {
			pageLockPosition.current = document.documentElement.scrollTop;

			document.documentElement.classList.add(`lockScroll_${id}`);
		}
	}, [id, modalOpen]);

	return {};
};
