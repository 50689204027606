import { useCallback } from "react";

type CheckoutStoreObject = {
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	line1: string;
	line2: string;
	city: string;
	province: string;
	postcode: string;
	parcelshop_id: string;
	userId: number;
	contact_via_email: boolean;
};

const KEY = "loggedInUserDataForCheckout";

export const useLoggedInCheckout = () => {
	const storeCheckoutData = useCallback((data: CheckoutStoreObject) => {
		if (typeof window === "undefined") {
			return;
		}
		const storeObject = JSON.stringify(data);
		sessionStorage.setItem(KEY, storeObject);
	}, []);

	const getStoredCheckoutData = useCallback(() => {
		if (typeof window === "undefined") {
			return null;
		}
		try {
			const data = sessionStorage.getItem(KEY);
			if (data) {
				return JSON.parse(data) as CheckoutStoreObject;
			}

			return null;
		} catch (_e: any) {
			return null;
		}
	}, []);

	const clearStoredCheckoutData = useCallback(() => {
		if (typeof window === "undefined") {
			return null;
		}

		sessionStorage.setItem(KEY, "");

		return null;
	}, []);

	return {
		storeCheckoutData,
		getStoredCheckoutData,
		clearStoredCheckoutData,
	};
};
