import { gql } from "@apollo/client";

import {
	BENEFITS_BAR_FIELDS,
	EXTRAS_FIELDS,
	FULL_WIDTH_FIELDS,
	HERO_FIELDS,
	IMAGE_TEXT_FIELDS,
	QUOTES_FIELDS,
	REVIEWS_FIELDS,
	STATISTIC_PANEL_FIELDS,
	SUCCESS_STORIES_FIELDS,
} from "./landingpageDato";

export const GET_MAIN_PAGE_SHOP_DATA = gql`
	${HERO_FIELDS}
	query {
		shop {
			hero {
				__typename
				...HeroFields
			}
			ctaCarousel {
				buttonLink
				buttonText
				buttonBehaviour
				buttonCategoryToOpen
				description
				heading
				image {
					responsiveImage(
						imgixParams: { fit: crop, w: 1920, h: 1080, auto: format }
					) {
						srcSet
						webpSrcSet
						sizes
						src
						width
						height
						aspectRatio
						alt
						title
						base64
					}
				}
			}
		}
	}
`;

export const GET_FEATURED_PRODUCTS = gql`
	query allRecipes {
		featuredProductList {
			recipes {
				handle
			}
			sideIcons
			backgroundColour
			title
		}
	}
`;

export const GET_FEATURED_PRODUCTS_DASHBOARD = gql`
	query allRecipes {
		dashboardUpsell {
			show
			sideIcons
			backgroundColour
			heading
			recipes {
				handle
			}
		}
	}
`;

export const GET_PRODUCT_SUMMARY_DATA = gql`
	query getRecipePage($handle: String) {
		recipe(filter: { handle: { eq: $handle } }) {
			callouts {
				feature
			}
			feedingGuide
			ingredientInformation
			protein
			proteinAmount
			fatAmount
			fibreAmount
			ashAmount
			kcalAmount
			nutritionalAdditives
		}
	}
`;

export const getProductOneOffPageData = gql`
	${IMAGE_TEXT_FIELDS}
	${BENEFITS_BAR_FIELDS}
	${REVIEWS_FIELDS}
	${QUOTES_FIELDS}
	${SUCCESS_STORIES_FIELDS}
	${FULL_WIDTH_FIELDS}
	${EXTRAS_FIELDS}
	${STATISTIC_PANEL_FIELDS}
	query getRecipePage($slug: String) {
		recipe(filter: { slug: { eq: $slug } }) {
			metadata {
				title
				description
			}

			callouts {
				feature
			}

			showInShop
			title
			description
			subtitle

			images {
				responsiveImage(
					imgixParams: { fit: crop, w: 1000, h: 600, auto: format }
				) {
					srcSet
					webpSrcSet
					sizes
					src
					width
					height
					aspectRatio
					alt
					title
					base64
				}
			}

			ingredientInformation
			protein
			proteinAmount
			fatAmount
			fibreAmount
			ashAmount
			kcalAmount
			nutritionalAdditives
			recipeType

			sections {
				__typename
				...ImageTextFields
				...BenefitsBarFields
				...ReviewsFields
				...QuotesFields
				...SuccessStoriesFields
				...FullWidthFields
				...ExtrasFields
				...StatisticPanelFields
			}
		}
	}
`;

export const getProductShopPageData = gql`
	${IMAGE_TEXT_FIELDS}
	${BENEFITS_BAR_FIELDS}
	${REVIEWS_FIELDS}
	${QUOTES_FIELDS}
	${SUCCESS_STORIES_FIELDS}
	${FULL_WIDTH_FIELDS}
	query getRecipePage($slug: String) {
		recipe(filter: { slug: { eq: $slug } }) {
			metadata {
				title
				description
			}
			callouts {
				feature
			}

			ingredientInformation
			protein
			proteinAmount
			fatAmount
			fibreAmount
			ashAmount
			kcalAmount
			nutritionalAdditives
			recipeType

			showInShop
			sections {
				__typename
				...ImageTextFields
				...BenefitsBarFields
				...ReviewsFields
				...QuotesFields
				...SuccessStoriesFields
				...FullWidthFields
			}
		}
	}
`;

export const CHARGE_CHECKOUT = gql`
	mutation ChargeCheckout($checkout_id: String!) {
		chargeCheckout(checkout_id: $checkout_id) {
			id
			stripe_error
			completed
			delivery_date(format: "Y-m-d")
		}
	}
`;

export const BUY_ONE_OFF_BOX = gql`
	mutation CreateOrder(
		$shopify_id: String
		$customer_id: String
		$delivery_date: String
		$charge_delivery: Boolean
		$payment_id: String
		$subscription_id: Int
		$input: OrderInput!
	) {
		CreateOrder(
			shopify_id: $shopify_id
			customer_id: $customer_id
			payment_id: $payment_id
			charge_delivery: $charge_delivery
			delivery_date: $delivery_date
			subscription_id: $subscription_id
			input: $input
		) {
			id
			subscription_id
			address_id
			discount_code
			shipping_weight
			price
			tax
			discount
			delivery
			total
			items {
				id
				itemable_id
				itemable_type
				title
				variant_id
				type
				uses
				quantity
				price
				default
				created_at
				updated_at
			}
			created_at
			updated_at
			error
		}
	}
`;
