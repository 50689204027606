import { useCallback, useState } from "react";

import { useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";

import { fetchEmailMailingListData } from "@/API/queries/emailMailingListDato";
import { identify, track } from "@/helpers/analytics";

import { EmailMarketingDato } from "./emailMailingList.types";

export const useEmailMailingList = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [successMsg, setSuccessMsg] = useState("");
	const [error, setError] = useState("");

	const methods = useForm({
		mode: "onSubmit",
	});

	const { handleSubmit, register } = methods;

	const onFormSubmit = useCallback(async (values: Record<string, string>) => {
		setIsLoading(true);

		try {
			// TODO: Validate whether user has already signed up - BE effort needed

			await identify({
				email: values.email,
				acceptsMarketing: 1,
			});

			/**
			 * Sends event to Amplitude
			 */
			track("Footer email capture", {
				email: values.email,
				url: window.location.href,
			});
		} catch (err) {
			console.error(err);
			setError("An error occured, please try again.");

			return;
		}

		setError("");
		setSuccessMsg("Thank you for signing up");

		setIsLoading(false);
	}, []);

	/**
	 * Gets Dato content for form title and body copy.
	 */
	const { data } = useQuery(fetchEmailMailingListData, {
		context: { clientName: "dato" },
		fetchPolicy: "network-only",
		nextFetchPolicy: "standby",
	});

	const datoData = data as EmailMarketingDato;

	return {
		handleSubmit,
		onFormSubmit,
		register,
		isLoading,
		successMsg,
		errors: methods.formState.errors,
		error,
		datoData,
	};
};
