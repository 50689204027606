import { useCallback, useEffect, useRef, useState } from "react";

import { useRouter } from "next/router";

import { useUserDataStore } from "@/store/UserDataStore";
import { useABTest } from "@/hooks/useABTest";

export const useHeader = ({ step }: { step?: number }) => {
	const [mobileNavOpen, setMobileNavOpen] = useState(false);
	const [itemOpen, setItemOpen] = useState("none");
	const [user] = useUserDataStore((state) => [state.user]);
	const { pathname } = useRouter();

	const [prevPath, setPrevPath] = useState<number>(0);
	const [currentPath, setCurrentPath] = useState("");

	const { startABTest } = useABTest();

	const triggerMobileNav = useCallback(() => {
		setItemOpen("none");
		setMobileNavOpen(!mobileNavOpen);
	}, [mobileNavOpen]);

	const openMenuItem = useCallback(
		(item: string) => {
			setItemOpen(itemOpen === item ? "none" : item);
			//setMobileNavOpen(false);
		},
		[itemOpen]
	);

	useEffect(() => {
		if (mobileNavOpen) {
			document.documentElement.style.overflowY = "hidden";
		} else {
			document.documentElement.style.overflowY = "auto";
		}
	}, [mobileNavOpen]);

	const navItemClicked = useCallback(() => {
		mobileNavOpen && setMobileNavOpen(false);
		itemOpen && setItemOpen("none");
	}, [setMobileNavOpen, mobileNavOpen, itemOpen]);

	const animateProgressBarForwards = useRef(false);

	useEffect(() => {
		try {
			if (!step) {
				return;
			}
			const storage = globalThis?.sessionStorage;

			const pathNameArray = pathname.split("/");

			if (pathNameArray[pathNameArray.length - 1] === currentPath) {
				return;
			}

			if (storage) {
				const lastStep = storage.getItem("lastStep");
				if (lastStep && step > parseInt(lastStep)) {
					animateProgressBarForwards.current = true;
				}

				storage.setItem("lastStep", `${step}`);
			}
		} catch (e) {
			console.log(e);
		}
	}, [prevPath, setPrevPath, setCurrentPath, currentPath, pathname, step]);

	return {
		triggerMobileNav,
		mobileNavOpen,
		itemOpen,
		navItemClicked,
		openMenuItem,
		setMobileNavOpen,
		user,
		animateProgressBarForwards: animateProgressBarForwards.current,
	};
};
