import { gql } from "@apollo/client";

export const fetchEmailMailingListData = gql`
	{
		emailMarketing {
			emailMailingListTitle
			emailMailingListBody
		}
	}
`;
