/* eslint-disable indent */
import { LegacyRef } from "react";

import c from "classnames";

import { SelectProps } from "@/types/inputFields";

import Styles from "./select.module.scss";
import { useSelect } from "./useSelect";
import { SelectOption } from "./SelectOption";

import { Label } from "../Label";
import { Icon } from "../Icon";

export const Select = ({
	type = "text",
	label,
	subLabel,
	labelType = "normal",
	name,
	id,
	register,
	validate,
	showErrors = true,
	errors,
	placeholder,
	options,
	extraText,
	errorMessages,
	value,
	onChange,
	errorMessageAddressee,
	setFocus,
	leftAlign,
	inline,
	required,
	unitToAppendDisplayValue = "",
	floatingErrors = false,
	menuAbove = false,
	onInput,
	sizing = "normal",
	...htmlInputProps
}: SelectProps) => {
	const {
		focussed,
		innerRef,
		onFocus,
		onBlur,
		onInputChange,
		selectOpen,
		onItemSelect,
		selectOptions,
		errorMessage,
		setSelectOpen,
	} = useSelect({
		options,
		errorMessages,
		errors,
		extraText,
		id,
		name,
		validate,
		errorMessageAddressee,
		setFocus,
		onChange,
		type,
		onInput,
	});

	return (
		<>
			<div
				className={c(
					Styles.selectWrapper,
					{ [Styles.isInline]: inline },
					{ [Styles[sizing]]: sizing !== "normal" }
				)}
				ref={innerRef as unknown as LegacyRef<HTMLDivElement>}
			>
				<Label
					htmlFor={name}
					label={label}
					subLabel={subLabel}
					labelIsActive={!!(value || focussed)}
					labelType={labelType}
				/>

				<div className={Styles.inputCont}>
					<input
						{...htmlInputProps}
						type={type}
						id={id}
						maxLength={validate?.maxLength}
						max={validate?.max}
						autoComplete="off"
						min={validate?.min}
						readOnly={type !== "search"}
						placeholder={placeholder}
						onClick={() => type !== "search" && setSelectOpen(!selectOpen)}
						className={c(Styles.input, {
							[Styles.inputError]: errors?.[name],
							[Styles.inputDown]: labelType === "inner",
							[Styles.inputSelect]: type !== "search",
							"text-left": leftAlign,
						})}
						onFocus={onFocus}
						{...(register &&
							register(name, {
								...validate,
								value: value || value === 0 ? value : "",
								onBlur,
								onChange: onInputChange,
								required,
							}))}
						value={
							type !== "search"
								? `${
										value || value === 0 ? value : ""
									}${unitToAppendDisplayValue}`
								: value
						}
					/>

					{type !== "search" && !htmlInputProps.disabled && (
						<Icon
							icon="ChevronDown"
							className={Styles.downIcon}
							onClick={() => type !== "search" && setSelectOpen(!selectOpen)}
						/>
					)}

					{extraText && <span className={Styles.extraText}>{extraText}</span>}

					{selectOptions && (
						<div
							className={c(Styles.listbox, {
								[Styles.open]: selectOpen,
								[Styles.above]: menuAbove,
							})}
							id={id}
							role="listbox"
						>
							{selectOptions?.map((option, index) => {
								return (
									<SelectOption
										key={option.value}
										option={option}
										index={index}
										isSelected={value?.includes(option.label)}
										onClick={onItemSelect}
										sizing={sizing}
									/>
								);
							})}
						</div>
					)}
				</div>

				{showErrors && errorMessage && (
					<div className={c({ [Styles.floatingError]: floatingErrors })}>
						{errorMessage}
					</div>
				)}
			</div>
		</>
	);
};
