import c from "classnames";
import Link from "next/link";
import { Image } from "react-datocms";

import { ButtonProps, ButtonVariants } from "@/types/inputFields";

import Styles from "./button.module.scss";

import { Icon } from "../Icon";

export const Button = ({
	url,
	disabled,
	variant = ButtonVariants.default,
	children,
	type,
	sizing = "normal",
	width = "full",
	leftIcon,
	rightIcon,
	className: classNames,
	isWorking = false,
	underline,
	selected,
	active = false,
	onClick,
	maintainPadding = false,
	center = false,
	fancyWorking = false,
	forwardRef,
	iconSpaced = false,
	left = false,
	image,
	darkWorkingIcon = false,
	...props
}: ButtonProps) => {
	const className = c(Styles.button, {
		[Styles.button_disabled]: disabled,
		[Styles.buttonClear]: variant === ButtonVariants.clear,
		[Styles.buttonClearLight]: variant === ButtonVariants.clearLight,
		[Styles.buttonClearAlternate]: variant === ButtonVariants.clearAlternate,
		[Styles.buttonGhost]: variant === ButtonVariants.ghost,
		[Styles.buttonGhostLight]: variant === ButtonVariants.ghostLight,
		[Styles.buttonNegative]: variant === ButtonVariants.negative,
		[Styles.buttonClearNegative]: variant === ButtonVariants.clearNegative,
		[Styles.buttonFacebook]: variant === ButtonVariants.facebook,
		[Styles.buttonWhatsapp]: variant === ButtonVariants.whatsapp,
		[Styles.maintainPadding]: maintainPadding,
		[Styles.selected]: selected,
		[Styles.large]: sizing === "large",
		[Styles.small]: sizing === "small",
		[Styles.smaller]: sizing === "smaller",
		[Styles.tiny]: sizing === "tiny",
		[Styles.relative]: width === "relative",
		[Styles.isWorking]: isWorking,
		[Styles.fancyWorking]: fancyWorking,
		[Styles.underline]: underline,
		[Styles.active]: active,
		[Styles.center]: center,
		[Styles.hasImage]: image,
		[Styles.darkWorkingIcon]: darkWorkingIcon,
		...(classNames ? { [classNames]: true } : {}),
	});

	const Content = (
		<div
			className={c(Styles.buttonTextWrapper, {
				[Styles.leftIcon]: !!leftIcon,
				[Styles.rightIcon]: !!rightIcon,
				[Styles.hasNoIcons]: !leftIcon && !rightIcon,
				[Styles.spaceBetween]: iconSpaced,
				[Styles.alignLeft]: left,
				[Styles.hasImage]: image,
			})}
		>
			{image?.responsiveImage?.src && (
				<div className={Styles.imgContain}>
					<Image
						data={image.responsiveImage}
						className={Styles.headerNavImgContain}
					/>
				</div>
			)}
			{leftIcon && (
				<Icon
					className={c(Styles.icon, Styles.iconLeft, {
						[Styles.iconLeftSmall]: sizing === "small",
					})}
					icon={leftIcon.icon}
					width={leftIcon.width || "24px"}
					height={leftIcon.height || "24px"}
				/>
			)}
			{children}
			{rightIcon && (
				<Icon
					className={c(Styles.icon, Styles.iconRight, {
						[Styles.iconRightSmall]: sizing === "small",
					})}
					icon={rightIcon.icon}
					width={rightIcon.width || "24px"}
					height={rightIcon.height || "24px"}
				/>
			)}
		</div>
	);

	return (
		<>
			{url ? (
				<Link href={url}>
					<a
						onClick={onClick as any}
						className={className}
						target={props.target}
						rel={props.rel}
					>
						{Content}
					</a>
				</Link>
			) : (
				<button
					className={className}
					type={type}
					disabled={disabled}
					onClick={!isWorking ? onClick : () => {}}
					{...props}
					ref={forwardRef}
				>
					{Content}
				</button>
			)}
		</>
	);
};
