import c from "classnames";

import Styles from "./errorMessage.module.scss";

export const ErrorMessage = ({
	children,
	className,
}: {
	children: React.ReactNode;
	className?: string;
}) => <p className={c(Styles.error, className)}>{children}</p>;
