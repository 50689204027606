import { useCallback, useEffect, useState } from "react";

import { useFunnelFieldErrors } from "@/hooks/useFunnelFieldErrors";
import { useOuterClick } from "@/hooks/useOuterClick";
import { SelectOption, SelectProps } from "@/types/inputFields";
import { track } from "@/helpers/analytics";

export const useSelect = ({
	options,
	errorMessages,
	errors,
	extraText,
	id,
	name,
	validate,
	errorMessageAddressee,
	onChange,
	setFocus,
	type,
	onInput,
}: Omit<SelectProps, "register">) => {
	const { errorMessage } = useFunnelFieldErrors({
		errorMessages,
		errors,
		extraText,
		name,
		validate,
		addressee: errorMessageAddressee,
	});

	const [focussed, setFocussed] = useState(false);
	const [selectOpen, setSelectOpen] = useState(false);
	const [selectOptions, setSelectOptions] = useState<SelectOption[]>();

	useEffect(() => {
		setSelectOptions(options);
	}, [options]);

	const doSearch = useCallback(
		(value: string) => {
			setSelectOpen(true);
			//onSearch(value);
			if (value === "") {
				setSelectOptions(options);
			} else {
				const secondOptions = [];
				const firstOptions = [];
				for (const item in options) {
					if (options[item as unknown as number].value) {
						const thisOption =
							options[item as unknown as number].value.toLowerCase();
						if (value.toLowerCase() === thisOption.substring(0, value.length)) {
							options && firstOptions.push(options[item as unknown as number]);
						} else {
							var found = thisOption.toLowerCase().indexOf(value.toLowerCase());
							if (found !== -1) {
								options &&
									secondOptions.push(options[item as unknown as number]);
							}
						}
					}
				}
				const newOptions = firstOptions.concat(secondOptions);
				setSelectOptions(newOptions);
			}
		},
		[options]
	);

	const onFocus = () => setFocussed(true);

	const onBlur = () => setFocussed(false);

	const onInputChange = useCallback(
		(event: any) => {
			options && type === "search" && doSearch(event.target.value);
			onInput?.(event.target.value);
		},
		[doSearch, onInput, options, type]
	);

	const onItemSelect = useCallback(
		(option: SelectOption) => {
			setFocus && setFocus(name);
			onChange && onChange(option.value, option.label);
			setSelectOpen(false);
		},
		[name, onChange, setFocus]
	);

	const innerRef = useOuterClick(() => {
		setSelectOpen(false);
	});

	useEffect(() => {
		if (id && errors?.[id]) {
			track("Funnel Form Error", {
				type: errors?.[id]?.type,
				field: id,
			});
		}
	}, [id, errors?.id, errors]);

	return {
		innerRef,
		focussed,
		setFocussed,
		selectOpen,
		selectOptions,
		errorMessage,
		onBlur,
		onFocus,
		setSelectOpen,
		doSearch,
		onInputChange,
		onItemSelect,
	};
};
