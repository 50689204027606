import { useCallback } from "react";

import { FieldErrors } from "@/types/inputFields";
import { useUserDataStore } from "@/store/UserDataStore";
import { useFunnelStepHelper } from "@/pagesComponents/Funnel/steps/useFunnelStepHelper";

import { track } from "../helpers";

type TrackFunnelError = {
	fieldId: string;
	errors: FieldErrors;
};

export const useTracking = () => {
	const [user] = useUserDataStore((state) => [state.user]);

	const { activePets } = useFunnelStepHelper();

	const trackFunnelError = useCallback(
		({ fieldId, errors }: TrackFunnelError) => {
			if (fieldId && errors?.[fieldId]) {
				track("Funnel Form Error", {
					type: errors?.[fieldId].type,
					field: fieldId,
				});
			}
		},
		[]
	);

	const trackAccordionOpen = useCallback((id: string) => {
		track("Open accordion", {
			category: "open",
			title: id,
		});
	}, []);

	const trackCalculatedRecipe = useCallback(() => {
		if (!activePets) {
			return;
		}

		activePets
			.filter((pet) => !!pet)
			.forEach((pet) => {
				track("Recipe Calculated", {
					category: "Recipe",
					label: pet.recipe,
					name: pet.name,
					allergies: pet.allergies,
					healthIssues: pet.healthIssues,
					weight: pet.weight,
					age: pet.age,
					breed: pet.breed,
					gender: pet.gender,
					birthday: pet.birthday,
					neutered: pet.neutered,
					recipe: pet.recipe,
					subSize: pet.baseSize,
					trialSize: pet.trialSize,
					scoops: pet.scoopsFood,
					skinnyness: pet.shape,
					fussiness: pet.fussy,
					goal: pet.goal,
					active: pet.activity,
					treatsFed: pet.treatsFed,
					diet: pet.diet,
					brands: pet.brands,
					lifestage: pet.lifestage,
					recipes: pet.shuffledRecipes,
					recipeCount: pet.shuffledRecipes.length,
					selectedRecipes: pet.selectedRecipes,
					email: user?.email,
					workingDog: pet.workingDog,
					digestion: pet.digestion,
					behavioural: pet.behavioural,
					stools: pet.stools,
					improveHealth: pet.improveHealth,
					mobility: pet.mobility,
					oral: pet.oral,
					coat: pet.coat,
					current_discount_code: user.voucher?.future_discounts?.[0]?.code,
					current_discount_deliveries: parseInt(
						user.voucher?.future_discounts?.[0]?.set_limit_amount || "1"
					),
					current_discount_type: user.voucher?.future_discounts?.[0]?.type,
					current_discount_value: user.voucher?.future_discounts?.[0]?.amount,
					initial_discount_code: user.voucher?.code,
					initial_discount_deliveries: parseInt(
						user.voucher?.set_limit_amount || "1"
					),
					initial_discount_type: user.voucher?.type,
					initial_discount_value: user.voucher?.amount,
					// TODO add tags and benefits here?
				});
			});

		activePets
			.filter((pet) => !pet)
			.forEach((pet) => {
				track("No Recipe Available", {
					category: "Recipe",
					label: "None Available",
					value: "None",
					allergies: pet.allergies,
					healthIssues: pet.healthIssues,
					weight: pet.weight,
					age: pet.age,
					breed: pet.breed,
					skinnyness: pet.shape,
					fussiness: pet.fussy,
					active: pet.activity,
					treatsFed: pet.treatsFed,
					goal: pet.goal,
					diet: pet.diet,
					brands: pet.brands,
					lifestage: pet.lifestage,
					digestion: pet.digestion,
					behavioural: pet.behavioural,
					stools: pet.stools,
					improveHealth: pet.improveHealth,
					mobility: pet.mobility,
					oral: pet.oral,
					coat: pet.coat,
					workingDog: pet.workingDog,
					email: user?.email,
				});
			});
	}, [
		activePets,
		user?.email,
		user.voucher?.amount,
		user.voucher?.code,
		user.voucher?.future_discounts,
		user.voucher?.set_limit_amount,
		user.voucher?.type,
	]);

	return { trackCalculatedRecipe, trackFunnelError, trackAccordionOpen };
};
