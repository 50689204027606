import c from "classnames";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";

import { ButtonVariants } from "@/types/inputFields";
import { useAccountDataStore } from "@/store/AccountDataStore";

import Styles from "./header.module.scss";

import { Button } from "../FormElements/Button";
import { useAccountRaf } from "@/pagesComponents/AccountRaf/useAccountRaf";
type Props = {
	mobileNavOpen: boolean;
	itemOpen: boolean | string;
	openMenuItem: (string: string) => void;
	navItemClicked: () => void;
};

export const AccountHeaderNav = ({ mobileNavOpen, navItemClicked }: Props) => {
	const { status } = useSession();
	const [user] = useAccountDataStore((state) => [state.user]);
	const { pathname, query } = useRouter();
	// Temporary fix
	const { currentScheme } = useAccountRaf();

	const subRoute = pathname.split("/")[2];
	const isAccountShop = subRoute === "shop";

	return (
		<>
			<div
				className={c(
					Styles.headerMobileNav,
					Styles.headerMobileNavAccount,
					Styles.headerMobileNavNoBasket,
					{
						[Styles.isActive]: mobileNavOpen,
					}
				)}
			>
				{/* {# Primary Nav #} */}

				<nav>
					<ul className={Styles.headerNavPrimary}>
						<li>
							<Button
								variant={ButtonVariants.ghost}
								className={Styles.noChildren}
								url="/account/dashboard"
								active={pathname === "/account/dashboard"}
								onClick={navItemClicked}
							>
								Dashboard
							</Button>
						</li>

						<li>
							<Button
								url={`/account/pet${
									query.petId ? `?petId=${query.petId}` : ""
								}`}
								variant={ButtonVariants.ghost}
								className={Styles.noChildren}
								active={pathname === "/account/pet"}
								onClick={navItemClicked}
							>
								{user && user.pets.length > 1 ? "Pets" : "Pet"}
							</Button>
						</li>

						<li>
							<Button
								url="/account/deliveries"
								variant={ButtonVariants.ghost}
								className={Styles.noChildren}
								onClick={navItemClicked}
								active={pathname === "/account/deliveries"}
							>
								Deliveries
							</Button>
						</li>

						<li>
							<Button
								active={pathname === "/account/shop"}
								url="/account/shop"
								variant={ButtonVariants.ghost}
								onClick={navItemClicked}
								className={Styles.noChildren}
							>
								Shop
							</Button>
						</li>

						{currentScheme && (
							<li>
								<Button
									active={pathname === "/account/raf"}
									url="/account/raf"
									variant={ButtonVariants.ghost}
									onClick={navItemClicked}
									className={Styles.noChildren}
								>
									Get £{currentScheme.amount / 100}
								</Button>
							</li>
						)}
					</ul>
				</nav>

				{/*  Secondary Nav  */}
				<ul className={Styles.headerNavSecondary}>
					{status === "authenticated" ? (
						<>
							{isAccountShop && (
								<li>
									<Button
										url="/account/shop/basket"
										variant={ButtonVariants.ghost}
										className={Styles.noChildren}
										onClick={navItemClicked}
										leftIcon={{
											icon: "ShoppingBasket",
											width: "36px",
											height: "36px",
										}}
									></Button>
								</li>
							)}

							<li>
								<Button
									url="/account/details"
									variant={ButtonVariants.ghost}
									className={Styles.noChildren}
									onClick={navItemClicked}
									leftIcon={{ icon: "User", width: "36px", height: "36px" }}
								></Button>
							</li>
						</>
					) : (
						<>
							<li>
								<Button
									url="/help"
									variant={ButtonVariants.ghost}
									className={Styles.noChildren}
									onClick={navItemClicked}
								>
									Help
								</Button>
							</li>

							<li>
								<Button
									url="/login"
									variant={ButtonVariants.ghost}
									className={Styles.noChildren}
									onClick={navItemClicked}
								>
									Login
								</Button>
							</li>
						</>
					)}
				</ul>
			</div>

			{/* Basket */}
			{/* <Basket></Basket> */}
		</>
	);
};
