import { useCallback, useEffect, useState } from "react";

const screenSizes = {
	xs: 421,
	sm: 601,
	md: 768,
	lg: 1024,
	xl: 1600,
};

export const useMediaQueries = () => {
	const [isTablet, setIsTablet] = useState(false);
	const [isTabletLarge, setIsTabletLarge] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const [isDesktop, setIsDesktop] = useState(false);
	const [hasLoaded, setHasLoaded] = useState(false);

	const windowResized = useCallback(() => {
		setIsMobile(window.matchMedia(`(max-width: ${screenSizes.sm}px)`).matches);
		setIsTablet(window.matchMedia(`(min-width: ${screenSizes.sm}px)`).matches);
		setIsTabletLarge(
			window.matchMedia(`(min-width: ${screenSizes.md}px)`).matches
		);
		setIsDesktop(window.matchMedia(`(min-width: ${screenSizes.lg}px)`).matches);
		setHasLoaded(true);
	}, []);

	useEffect(() => {
		window.addEventListener("resize", windowResized);

		return () => {
			window.removeEventListener("resize", windowResized);
		};
	}, [windowResized]);

	useEffect(() => {
		windowResized();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		isTablet,
		isTabletLarge,
		isMobile,
		isDesktop,
		hasLoaded,
	};
};
