import { useCallback } from "react";

import { usePreventScroll } from "@/hooks/usePreventScroll";

type ModalProps = {
	modalOpen: boolean;
	onClose?: (event: React.MouseEvent<HTMLElement>) => void;
	closeable: boolean;
};
export const useModal = ({ modalOpen, onClose, closeable }: ModalProps) => {
	const closeModal = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			event.stopPropagation();
			// @ts-expect-error Remove selection, this happens when clicking too many times, it's annoying so just clear the selection of the browser
			window && window.getSelection !== null && window.getSelection().empty();
			closeable && onClose && onClose(event);
		},
		[onClose, closeable]
	);

	usePreventScroll({ modalOpen, id: "modal" });

	return {
		closeModal,
	};
};
