import { useCallback, useEffect, useState } from "react";

import { useRouter } from "next/router";

import { ButtonVariants } from "@/types/inputFields";
import { track } from "@/helpers/analytics";
import { useUserDataStore } from "@/store/UserDataStore";

import Styles from "./cookieBanner.module.scss";

import { Button } from "../FormElements/Button";
import { SlideToggle } from "../FormElements/SlideToggle";

export const CookieBanner = () => {
	const [userConsent, updateUserConsent] = useUserDataStore((state) => [
		state.userConsent,
		state.updateUserConsent,
	]);

	const router = useRouter();

	const [showManage, setShowManage] = useState(false);
	const [functionalCookies, setFunctionalCookies] = useState(true);
	const [marketingCookies, setMarketingCookies] = useState(true);

	useEffect(() => {
		if (userConsent === null && router.isReady) {
			updateUserConsent({
				marketing: false,
				functional: false,
				selected: false,
			});
		}
	}, [updateUserConsent, userConsent, router.isReady]);

	const acceptAll = useCallback(() => {
		updateUserConsent({ marketing: true, functional: true, selected: true });
	}, [updateUserConsent]);

	const acceptSome = useCallback(() => {
		updateUserConsent({
			marketing: marketingCookies,
			functional: functionalCookies,
			selected: true,
		});
	}, [functionalCookies, marketingCookies, updateUserConsent]);

	if (
		userConsent &&
		userConsent !== null &&
		userConsent.selected === false &&
		router.isReady
	) {
		return (
			<>
				<div className={Styles.cookieBanner}>
					{showManage && (
						<div className={Styles.extraInfo}>
							<p>
								Please choose which additional cookies to use to give you the
								best experience on our website.
							</p>
							<div className={Styles.toggle}>
								<SlideToggle
									label="Required cookies"
									name="requiredCookies"
									value={true}
									disabled={true}
									isLoading={false}
									onChange={() => {}}
								/>
								<p>
									Reqired to allow us to save your progress whilst creating a
									plan, remember your pet's details and store your login
									details.
								</p>
							</div>
							<div className={Styles.toggle}>
								<SlideToggle
									label="Functional cookies"
									name="functionalCookies"
									value={functionalCookies}
									isLoading={false}
									onChange={() => {
										setFunctionalCookies(!functionalCookies);
									}}
								/>
								<p>Ensures the website looks beautiful for your experience</p>
							</div>
							<div className={Styles.toggle}>
								<SlideToggle
									label="Marketing cookies"
									name="marketingCookies"
									value={marketingCookies}
									isLoading={false}
									onChange={() => {
										setMarketingCookies(!marketingCookies);
									}}
								/>
								<p>See adverts relevant to you and your pet</p>
							</div>
						</div>
					)}
					{!showManage && (
						<p>
							This website uses cookies to optimise our website and improve your
							experience.
						</p>
					)}
					<div className={Styles.btnHolder}>
						{showManage ? (
							<Button
								variant={ButtonVariants.ghostLight}
								onClick={() => acceptSome()}
							>
								Save changes
							</Button>
						) : (
							<Button
								variant={ButtonVariants.ghostLight}
								onClick={() => {
									setShowManage(true);
									setFunctionalCookies(false);
									setMarketingCookies(false);
								}}
							>
								Manage cookies
							</Button>
						)}

						<Button onClick={() => acceptAll()}>Accept all</Button>
					</div>
				</div>
			</>
		);
	}

	return <></>;
};
