import { useCallback, useState } from "react";

import c from "classnames";
import Image from "next/image";

import { User } from "@/types/user";
import { toSentence } from "@/helpers/helperFunctions";
import { ModalVariants } from "@/types/layout";

import Styles from "./voucherModal.module.scss";

import { Modal } from "../Modal";
import { Button } from "../FormElements/Button";

type Props = {
	voucher: User["voucher"];
	referral?: User["referral"];
};

export const VoucherModal = ({ voucher, referral }: Props) => {
	const [modalOpen, setModalOpen] = useState(true);

	const onModalClose = useCallback(() => {
		setModalOpen(false);
	}, [setModalOpen]);

	return (
		<Modal
			onClose={onModalClose}
			modalOpen={modalOpen}
			type={ModalVariants.info}
		>
			{referral && (
				<div className={Styles.voucherModal}>
					<h2>
						You're loved by {referral.name}
						{referral.pets.length > 0 && <> and {toSentence(referral.pets)}</>}
					</h2>
					<h4>You're getting our best offer</h4>
				</div>
			)}
			<div className={Styles.content}>
				<div className={Styles.discount}>
					<span className={Styles.bannerNumber}>
						{voucher?.type === "fixed"
							? "£" + ((voucher.amount || 0) / 100).toFixed(0)
							: voucher?.amount + "%"}
					</span>{" "}
					{voucher?.type_limit === "non_transition"
						? "off when you restart your plan"
						: "off your first box"}
					{voucher?.future_discounts?.map((discount, i) => (
						<div className={c(Styles.secondDiscount)} key={i}>
							&amp;{" "}
							<span className={Styles.bannerNumber}>
								{discount?.type === "fixed"
									? "£" + (discount.amount / 100).toFixed(0)
									: discount?.amount + "%"}
							</span>{" "}
							off your{" "}
							{discount.set_limit_amount === "1" ? (
								<strong>second</strong>
							) : (
								<>next {discount.set_limit_amount}</>
							)}
						</div>
					))}
				</div>
				<Button onClick={onModalClose}>Let's get started</Button>
				{referral && (
					<p className={Styles.smallText}>
						+ {referral.name} gets £{referral.scheme.amount / 100} towards their
						next box!
					</p>
				)}
			</div>
		</Modal>
	);
};
