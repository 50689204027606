import { ButtonVariants } from "@/types/inputFields";
import { emailValidator } from "@/consts/fieldValidators";

import { useEmailMailingList } from "./useEmailMailingList";
import Styles from "./emailMailingList.module.scss";
import { Props } from "./emailMailingList.types";

import { Button } from "../FormElements/Button";
import { Input } from "../FormElements";
import { Icon } from "../FormElements/Icon";

export const EmailMailingList = ({
	className,
	submitBtnVariant = ButtonVariants.clearLight,
}: Props) => {
	const {
		handleSubmit,
		onFormSubmit,
		register,
		isLoading,
		successMsg,
		errors,
		datoData,
	} = useEmailMailingList();

	const titleText: string =
		datoData?.emailMarketing.emailMailingListTitle || "Join our VIP list";
	const bodyText: string =
		datoData?.emailMarketing.emailMailingListBody ||
		"Be the first to hear about new product launches, exclusive competitions and helpful dog content.";

	return (
		<div className={className} aria-live="polite">
			<h4 className="mobileSize">{titleText}</h4>

			<p>{bodyText}</p>

			{successMsg ? (
				<div className={Styles.confirmation}>
					<Icon icon="Verified" />

					<p>{successMsg}</p>
				</div>
			) : (
				<form
					onSubmit={handleSubmit(onFormSubmit)}
					className={Styles.emailForm}
				>
					<Input
						leftAlign={true}
						name="email"
						id="email"
						placeholder="Enter your email address"
						label="Email address"
						labelType="hidden"
						register={register}
						errors={errors}
						errorClassName={Styles.error}
						errorMessages={{
							required: emailValidator.errorMessages?.required,
							pattern: emailValidator.errorMessages?.invalid,
						}}
						validate={{
							required: true,
							pattern: emailValidator.pattern,
						}}
					/>

					<Button
						variant={submitBtnVariant}
						width="relative"
						isWorking={isLoading}
						disabled={Object.keys(errors).length > 0}
					>
						Sign up
					</Button>
				</form>
			)}
		</div>
	);
};
