import c from "classnames";

import Styles from "./InputQuantityControl.module.scss";

import { Icon, IconOptions } from "../Icon";

type Props = {
	onClick: () => void;
	isDisabled?: boolean;
	icon: IconOptions;
};

export const InputQuantityControl = ({ onClick, isDisabled, icon }: Props) => {
	return (
		<button
			type="button"
			disabled={isDisabled}
			className={c(Styles.quantity, {
				[Styles.inactive]: isDisabled,
			})}
			onClick={onClick}
		>
			<Icon icon={icon} width="24" height="24" className={Styles.icon} />
		</button>
	);
};
