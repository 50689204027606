/* eslint-disable indent */
import { LegacyRef, useEffect, useState } from "react";

import c from "classnames";
import { useSession } from "next-auth/react";
import slugify from "react-slugify";

import { useUserDataStore } from "@/store/UserDataStore";
import { ButtonVariants } from "@/types/inputFields";
import { initializeApollo } from "@/API/apolloClient";
import { fetchNavigationData } from "@/API/queries/navigationPagesDato";
import { Image as ImageType } from "@/types/page";
import { useMediaQueries } from "@/hooks/useMediaQueries";
import { useOuterClick } from "@/hooks/useOuterClick";

import Styles from "./header.module.scss";

import { Basket } from "../Basket";
import { Button } from "../FormElements/Button";

type NavItem = {
	title: string;
	link: { slug: string; __typename: string };
	image: ImageType;
	childItems: NavItem[];
};

type Pages = {
	pages: NavItem[];
};

type Props = {
	mobileNavOpen: boolean;
	itemOpen: boolean | string;
	openMenuItem: (string: string) => void;
	navItemClicked: () => void;
};

export const HeaderNav = ({
	mobileNavOpen,
	itemOpen,
	openMenuItem,
	navItemClicked,
}: Props) => {
	const [user] = useUserDataStore((state) => [state.user]);
	const { status } = useSession();

	const [secondaryItemOpen, setSecondaryItemOpen] = useState("");
	const [navData, setNavData] = useState<Pages>();
	const [showBasket, setShowBasket] = useState(false);

	const { isDesktop } = useMediaQueries();

	const innerRef = useOuterClick(() => {
		if (isDesktop) {
			navItemClicked();
		}
	});

	const [checkout] = useUserDataStore((state) => [state.checkout]);

	useEffect(() => {
		const getData = async () => {
			const apolloClient = initializeApollo();
			const { data } = await apolloClient.query({
				query: fetchNavigationData,
				context: { clientName: "dato" },
			});
			setNavData(data.navigation);
		};

		getData();
	}, []);

	useEffect(() => {
		if ((checkout?.lineItems?.length || 0) > 0) {
			setShowBasket(false);
		} else {
			setShowBasket(true);
		}
	}, [checkout?.lineItems?.length]);

	return (
		<>
			<div
				className={c(Styles.headerMobileNav, Styles.isShort, {
					[Styles.isActive]: mobileNavOpen,
				})}
				ref={innerRef as unknown as LegacyRef<HTMLDivElement>}
			>
				{/* {# Primary Nav #} */}

				<nav>
					<ul className={Styles.headerNavPrimary}>
						{navData?.pages.map((page, i) => (
							<li key={i}>
								<Button
									variant={ButtonVariants.ghost}
									url={
										page.link &&
										((page.link.__typename === "RecipeRecord" && "/recipes") ||
											"/") + page.link.slug
									}
									rightIcon={
										(!page.link && {
											icon: isDesktop ? "ChevronDown" : "ChevronRight",
											width: isDesktop ? "24px" : "16px",
											height: isDesktop ? "24px" : "16px",
										}) ||
										undefined
									}
									onClick={() => {
										!page.link && openMenuItem(slugify(page.title));
										page.link && navItemClicked();
									}}
									width="full"
									iconSpaced={true}
								>
									{page.title}
								</Button>
								{page.childItems.length > 0 && (
									<ul
										className={c(Styles.headerNavPrimaryChild, {
											[Styles.isActive]: itemOpen === slugify(page.title),
										})}
									>
										<div className="container containerNoPad">
											<li className={Styles.headerNavPrimaryChildHeading}>
												<h2 className="no-bottom">
													<Button
														variant={ButtonVariants.ghost}
														leftIcon={{ icon: "ChevronLeft" }}
														onClick={() => {
															openMenuItem("");
														}}
														width="full"
														left={!isDesktop}
													>
														{page.title}
													</Button>
												</h2>
											</li>
											{page.childItems.map((childPage, k) => (
												<li key={k}>
													<Button
														url={
															childPage.link &&
															((childPage.link.__typename === "RecipeRecord" &&
																"/recipes") ||
																"/") + childPage.link.slug
														}
														variant={ButtonVariants.ghost}
														left={!isDesktop}
														onClick={() => {
															setSecondaryItemOpen(
																secondaryItemOpen === slugify(childPage.title)
																	? ""
																	: slugify(childPage.title)
															);
															childPage.link && navItemClicked();
														}}
														image={childPage.image}
														rightIcon={
															(!childPage.link &&
																!isDesktop && {
																	icon:
																		secondaryItemOpen ===
																		slugify(childPage.title)
																			? "IconMinus"
																			: "IconPlus",
																}) ||
															undefined
														}
														iconSpaced={!childPage.link}
														width="full"
													>
														{childPage.title}
													</Button>
													{childPage.childItems.length > 1 && (
														<ul
															className={c(
																Styles.headerNavPrimaryChildSecondary,
																{
																	[Styles.isActive]:
																		secondaryItemOpen ===
																		slugify(childPage.title),
																}
															)}
														>
															{childPage.childItems.map((childestPage, j) => (
																<li key={j}>
																	{" "}
																	<Button
																		url={
																			((childestPage.link?.__typename ===
																				"RecipeRecord" &&
																				"/recipes/") ||
																				"/") + childestPage.link?.slug
																		}
																		variant={ButtonVariants.ghost}
																		className={Styles.noChildren}
																		sizing="small"
																		left
																		onClick={() => navItemClicked()}
																	>
																		{childestPage.title}
																	</Button>
																</li>
															))}
														</ul>
													)}
												</li>
											))}
										</div>
									</ul>
								)}
							</li>
						))}
					</ul>
				</nav>

				{/*  Secondary Nav  */}
				{/* TODO: CHANGE TEXT FOR LOGGED IN OR NOT */}
				<ul className={Styles.headerNavSecondary}>
					<li>
						<Button
							url="/help"
							variant={ButtonVariants.ghost}
							className={Styles.noChildren}
							leftIcon={(!isDesktop && { icon: "Question" }) || undefined}
							left
						>
							Help
						</Button>
					</li>
					<li>
						{status === "authenticated" ? (
							<Button
								url="/account/dashboard"
								variant={ButtonVariants.ghost}
								className={Styles.noChildren}
								left
								leftIcon={(!isDesktop && { icon: "User" }) || undefined}
							>
								Account
							</Button>
						) : (
							<Button
								url="/login"
								variant={ButtonVariants.ghost}
								className={Styles.noChildren}
								left
								leftIcon={(!isDesktop && { icon: "User" }) || undefined}
							>
								Login
							</Button>
						)}
					</li>
				</ul>
			</div>

			{/* Basket */}
			{!showBasket ? (
				<Basket />
			) : (
				<div className={Styles.headerBtn}>
					<Button url="/signup" width="relative">
						Create plan
					</Button>
				</div>
			)}
		</>
	);
};
