import { useCallback, useEffect, useState } from "react";

type Props = {
	quantity: number;
	setQuantity: (quantity: number) => void;
};

type Returns = {
	moreBtnClick: () => void;
	lessBtnClick: () => void;
	isLoading: boolean;
};

export const useQuantitySelector = ({
	quantity,
	setQuantity,
}: Props): Returns => {
	const [isLoading, setIsLoading] = useState(false);
	useEffect(() => {
		setIsLoading(false);
	}, [quantity]);

	const btnClicked = useCallback(
		(qty: number) => {
			setQuantity(qty);
			setIsLoading(true);
		},
		[setQuantity]
	);

	const lessBtnClick = useCallback(
		() => quantity > 0 && btnClicked(quantity - 1),
		[btnClicked, quantity]
	);

	const moreBtnClick = useCallback(
		() => btnClicked(quantity + 1),
		[btnClicked, quantity]
	);

	return {
		moreBtnClick,
		lessBtnClick,
		isLoading,
	};
};
