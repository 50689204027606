import c from "classnames";

import { SelectOption as SelectOptionType } from "@/types/inputFields";

import Styles from "./selectOption.module.scss";
type Props = {
	option: SelectOptionType;
	index: number;
	isSelected?: boolean;
	onClick: (selection: SelectOptionType) => void;
	sizing: "normal" | "tiny";
};
export const SelectOption = ({
	option,
	index,
	isSelected,
	onClick,
	sizing,
}: Props) => {
	return (
		<div
			role="option"
			aria-selected="false"
			className={c({
				[Styles.option]: true,
				// @ts-ignore
				[Styles.selected]: isSelected,
				[Styles.recommended]: option.recommended,
				[Styles.tiny]: sizing === "tiny",
			})}
			data-value={option.value}
			key={index}
			onClick={() => onClick(option)}
		>
			{option.label}{" "}
			{option.caption && (
				<span className={Styles.caption}>{option.caption}</span>
			)}
		</div>
	);
};
