import { gql } from "@apollo/client";

export const RECIPE_FIELDS = gql`
	fragment RecipeFields on RecipeRecord {
		slug
	}
`;

export const LANDING_FIELDS = gql`
	fragment LandingFields on NewLandingPageRecord {
		slug
	}
`;

export const fetchNavigationData = gql`
	${RECIPE_FIELDS}
	${LANDING_FIELDS}
	query FetchNavigationData {
		navigation {
			pages {
				link {
					__typename
					...RecipeFields
					...LandingFields
				}
				title
				childItems {
					link {
						__typename
						...RecipeFields
						...LandingFields
					}
					title
					image {
						responsiveImage(
							imgixParams: { fit: crop, w: 128, h: 128, auto: format }
						) {
							srcSet
							webpSrcSet
							sizes
							src
							width
							height
							aspectRatio
							alt
							title
							base64
						}
					}
					childItems {
						link {
							__typename
							...RecipeFields
							...LandingFields
						}
						title
					}
				}
			}
		}
	}
`;
