/* eslint-disable indent */
import { useCallback, useEffect, useRef, useState } from "react";

import { useFunnelFieldErrors } from "@/hooks/useFunnelFieldErrors";
import { InputFieldProps } from "@/types/inputFields";
import { track } from "@/helpers/analytics";

export const useInput = ({
	errorMessages,
	errors,
	extraText,
	name,
	validate,
	addressee,
	onChange,
	type,
	value,
	increment,
	step,
	onBlur,
	id,
	track: trackEvent,
	min,
	max,
}: InputFieldProps) => {
	const wrapperRef = useRef<HTMLDivElement>(null);

	const [focussed, setFocussed] = useState(false);
	const [revealPassword, setRevealPassword] = useState(false);
	const hasSetInitialPrefilledValue = useRef(false);

	const { errorMessage } = useFunnelFieldErrors({
		errorMessages,
		errors,
		extraText,
		name,
		validate,
		addressee,
	});

	useEffect(() => {
		if (!id) {
			return;
		}

		const element = document.getElementById(id);

		if (
			element &&
			!!element.nodeValue &&
			!hasSetInitialPrefilledValue.current &&
			onChange
		) {
			hasSetInitialPrefilledValue.current = true;
			onChange(element.nodeValue);
		}
	}, [id, onChange]);

	const onFocus = useCallback(() => {
		if (trackEvent) {
			track("input focus", {
				category: "focus",
				input: name,
			});
		}
		setFocussed(true);
	}, [name, trackEvent]);

	const onBlurCallback = useCallback(
		(ev: any) => {
			setFocussed(false);
			onBlur?.(ev);
		},
		[onBlur]
	);

	const getValue = useCallback(
		(addition: boolean = false) => {
			const roundedValue = value !== "" ? parseFloat(`${value}`) : 1;
			const stepNum = increment ? increment : step ? parseFloat(`${step}`) : 1;

			return (
				Math.round(
					(addition ? roundedValue + stepNum : roundedValue - stepNum) * 10
				) / 10
			);
		},
		[increment, step, value]
	);

	const onQuantityIncreaseClick = useCallback(() => {
		const val = getValue(true);
		if (parseFloat(`${val}`) > parseFloat(`${max}`)) {
			onChange && onChange(`${max}`);
			return;
		}
		onChange && onChange(`${getValue(true)}`);
	}, [getValue, max, onChange]);

	const onQuantityDecreaseClick = useCallback(() => {
		const val = getValue();
		if (parseFloat(`${val}`) < parseFloat(`${min}`)) {
			onChange && onChange(`${min}`);
			return;
		}
		onChange && onChange(`${getValue()}`);
	}, [getValue, min, onChange]);

	const hasNumberControls = type === "number"; // !!onQuantityDecrease && !!onQuantityIncrease;

	const toggleShowHidePassword = useCallback(() => {
		setRevealPassword(!revealPassword);
	}, [revealPassword]);

	return {
		focussed,
		setFocussed,
		onFocus,
		onBlurCallback,
		onQuantityIncreaseClick,
		onQuantityDecreaseClick,
		errorMessage,
		hasNumberControls,
		wrapperRef,
		revealPassword,
		toggleShowHidePassword,
	};
};
