import { MutableRefObject } from "react";

import c from "classnames";

import { ModalVariants } from "@/types/layout";

import { useModal } from "./useModal";
import Styles from "./modal.module.scss";

import { Icon } from "../FormElements/Icon";

type Props = {
	children: React.ReactNode;
	modalOpen?: boolean;
	onClose?: (event: React.MouseEvent<HTMLElement>) => void;
	closeable?: boolean;
	thisRef?: MutableRefObject<null>;
	outerCloseable?: boolean;
	className?: string;
	showClose?: boolean;
	type?: ModalVariants;
	modalClassName?: string;
	responsiveWidth?: boolean;
	darkTheme?: boolean;
	fullWidthMobile?: boolean;
};

export const Modal = ({
	children,
	modalOpen = true,
	onClose,
	closeable = true,
	thisRef,
	showClose = true,
	className = "",
	modalClassName = "",
	type = ModalVariants.default,
	darkTheme = false,
	// Allows the modal to be wider on wider screens.
	responsiveWidth = false,
	// Full width at mobile
	fullWidthMobile = false,
}: Props) => {
	const { closeModal } = useModal({
		modalOpen,
		onClose,
		closeable,
	});

	return (
		<>
			{modalOpen && (
				<div
					className={c(Styles.modalContainer, {
						[className]: !!className,
					})}
					onClick={closeModal}
					id="modal"
				>
					<div
						className={c(Styles.modalWrapper, {
							[Styles.responsiveWidth]: responsiveWidth,
							[Styles.fullWidthMobile]: fullWidthMobile,
							[modalClassName]: !!modalClassName,
							[Styles.infoModal]: type === ModalVariants.info,
							[Styles.darkTheme]: darkTheme,
						})}
					>
						{closeable && showClose && (
							<div className={Styles.modalClose} onClick={closeModal}>
								<Icon icon="Close" />
							</div>
						)}
						<div
							className={Styles.modal}
							ref={thisRef}
							onClick={(e) => e.stopPropagation()}
						>
							{children}
						</div>
					</div>
				</div>
			)}
		</>
	);
};
